.body {
  padding: 40px;
  text-align: center;
  display: flex;
}

.about {
  margin: 40px auto 40px;
}

.image {
  border-radius: 50%;
}

.heading {
  padding-top: 20px;
  padding-bottom: 20px;
}

.paragraph {
  display: flex;
  max-width: 500px;
  margin-bottom: 16px;
}
